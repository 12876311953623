import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';

import Detail from '../components/beers/detail/seltzer-detail';

export const SeltzerTemplate = (props) => <Detail {...props} />;

const Beer = ({ pageContext, data }) => {
	const seltzer = data.markdownRemark.frontmatter;
	return (
		<Layout isSeltzer={true}>
			<SEO title={`${seltzer.title}`} />
			<SeltzerTemplate
				mast={seltzer.mast}
				singleCan={seltzer.singleCan.image}
				multiCan={seltzer.multiCan.image}
				tap={seltzer.tap.image}
				tagline={seltzer.tagline}
				blurb={seltzer.blurb}
				packageAvail={seltzer.packageAvail}
				recipe={seltzer.recipe}
				style={seltzer.style}
				taste={seltzer.taste}
				pairing={seltzer.pairing}
				invert={seltzer.invert}
				next={seltzer.next}
				prev={seltzer.prev}
				buyLink={seltzer.buyLink}
				fullName={`${seltzer.title} ${seltzer.type}`}
			/>
		</Layout>
	);
};

export default withPreview(Beer);

export const beerQuery = graphql`
query SeltzerQuery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    frontmatter {
      title
      templateKey
      mast
      tagline
      blurb
      packageAvail
      recipe
      style
      taste
      pairing
      next
      prev
      buyLink
      invert
      type
      singleCan {
        image
        alt
      }
      multiCan {
        image
        alt
      }
      tap {
        image
        alt
      }      
    } 
  }
}
`;
